<template>
  <el-card style="min-height: 45rem">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form label-width="12rem" style="padding: 2rem;">
          <el-form-item label="汇聚支付商户号">
            <el-input v-model="form.mc_id"></el-input>
          </el-form-item>
          <el-form-item label="md5密钥">
            <el-input v-model="form.md5_key"></el-input>
          </el-form-item>
          <el-form-item label="微信公众号报备商户编号">
            <el-input v-model="form.official_trade_mc_no"></el-input>
            <div class="y-desc">报备绑定公众号appid 需与 系统使用公众号appid相同</div>
          </el-form-item>
          <el-form-item label="微信小程序报备商户编号">
            <el-input v-model="form.mini_trade_mc_no"></el-input>
            <div class="y-desc">报备绑定小程序appid  需与 系统使用小程序appid相同</div>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form label-width="8rem" style="padding: 2rem;">
          <el-form-item label="付款产品">
            <el-radio-group v-model="form.transfer_project_code">
              <el-radio label="BANK_PAY_ORDINARY_ORDER">普通代付</el-radio>
              <el-radio label="BANK_PAY_DAILY_ORDER">朝夕付</el-radio>
              <el-radio label="BANK_PAY_MAT_ENDOWMENT_ORDER">任意付</el-radio>
              <el-radio label="BANK_PAY_COMPOSE_ORDER">组合付</el-radio>
            </el-radio-group>
            <div class="y-desc" style="color: red;">
              普通代付：在工作日9:00-17:30出款，使用“可取余额”，正常2小时内到账，建议在17:00前提交（并在商户后台审核）订单；<br><br>
              朝夕付：不限出款时间，使用“可取余额”，正常5分钟内到账，偶尔半小时也属于正常时间范围；<br><br>
              任意付：不限出款时间，使用“可垫资余额”，正常5分钟内到账，偶尔半小时也属于正常时间范围；<br><br>
              <strong>此介绍内容摘自汇聚支付官方文档，具体以产品实际表现为准，资金类操作请先操作测试确认符合预期</strong>
            </div>
          </el-form-item>
          <el-form-item v-if="form.transfer_project_code === 'BANK_PAY_COMPOSE_ORDER'" label="优先使用产品">
            <el-radio-group v-model="form.transfer_first_product_code">
              <el-radio label="BANK_PAY_ORDINARY_ORDER">普通代付</el-radio>
              <el-radio label="BANK_PAY_DAILY_ORDER">朝夕付</el-radio>
              <el-radio label="BANK_PAY_MAT_ENDOWMENT_ORDER">任意付</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="打款复核">
            <el-switch v-model="form.transfer_checked"></el-switch>
          </el-form-item>
          <el-form-item label="付款用途">
            <el-radio-group v-model="form.transfer_paid_use">
              <el-radio label="201">工资资金</el-radio>
              <el-radio label="202">活动经费</el-radio>
              <el-radio label="203">养老金</el-radio>
              <el-radio label="204">货款</el-radio>
              <el-radio label="205">劳务费</el-radio>
              <el-radio label="206">保险理财</el-radio>
              <el-radio label="207">资金下发</el-radio>
              <el-radio label="208">营业退款</el-radio>
              <el-radio label="209">其他</el-radio>
              <el-radio label="210">退回款项</el-radio>
              <el-radio label="211">消费款项</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: "joinpay",
  data() {
    return {
      form: {
        mc_id: "",
        md5_key: "",
        official_trade_mc_no: "",
        mini_trade_mc_no: "",

        transfer_project_code:"BANK_PAY_ORDINARY_ORDER",
        transfer_first_product_code:"BANK_PAY_ORDINARY_ORDER",
        transfer_checked:false,
        transfer_paid_use:"",
      },
    }
  },
  mounted() {
    this.load();},
  methods: {
    load() {
      this.$u.api.shop.sys.joinPay().then(res => {
        this.form = res;
      })
    },
    submit() {
      this.$u.api.shop.sys.joinPayEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc {
  line-height: 1rem
}
</style>